html,
body,
#root,
#app {
    height: 100%;
    width: 100%;
    margin: 0;
}

.editor {
    flex: 1;
}

.fullheight {
    height: 100%;
}

canvas {
    border: 4px solid black;
    image-rendering: pixelated;
    image-rendering: crisp-edges;
}